import { FaAngleDoubleDown } from 'react-icons/fa';
import React from 'react';

export interface IntroductionProps {
    title: string;
    extendedName: string;
}

export function IntroductionSectionRender(props: IntroductionProps) {
    return (
        <div className="container">
            <div className="text-center">
                <h2 className="section-heading text-uppercase">L'importance du {props.title}</h2>
            </div>
            <div className="text-justify">
                <p>
                    À travers les années, ce qui fait de Polytechnique une école qu'on est fier d'avoir fréquentée,
                    c'est sans équivoque l'implication étudiante.
                </p>
                <p>
                    Si tu as aimé participer à des évènements comme le Vins et Fromages, la compétition informatique,
                    les conférences et les soirées de jeux en ligne, sache qu'il y a un comité d'étudiants dévoués qui
                    se trouve derrière toutes ces commodités.
                </p>
                <p>
                    Si tu veux faire partie de l'équipe, on t'invite à déposer ta candidature. Dans le même ordre
                    d'idées, si tu aimes bien participer aux évènements, on t'invite à prendre le temps de voter pour
                    les candidats qui, selon toi, représenteront le mieux notre communauté étudiante en{' '}
                    {props.extendedName}.
                </p>
            </div>
        </div>
    );
}

export interface ApplicationSectionProps {
    title: string;
    electionDeadline: string;
    electionsEmail: string;
}

export function ApplicationSectionComponent(props: ApplicationSectionProps) {
    return (
        <div className="container">
            <div className="text-center mb-4">
                <h2 className="section-heading text-uppercase">Application</h2>
                <h3 className="section-subheading">
                    Un poste t'intéresse? Écris un courriel à l'adresse ci-dessous d'ici le{' '}
                    <strong>{props.electionDeadline}!</strong>
                </h3>
            </div>
            <div>
                <h6>L'application doit contenir:</h6>
                <ul>
                    <li>Prénom</li>
                    <li>Nom</li>
                    <li>Année (1, 2, etc.)</li>
                    <li>Matricule</li>
                    <li>Adresse courriel @polymtl.ca</li>
                    <li>Génie (informatique ou logiciel)</li>
                    <li>
                        Poste pour lequel tu appliques <i>*Seulement UN*</i>
                    </li>
                    <li>Photo de ton visage - image carrée (1:1) en format .jpg (max 2 MB)</li>
                    <li>Lettre de motivation - à écrire directement dans le corps du courriel</li>
                </ul>
                <div className="text-center">
                    <span className="arrows-icon" style={{ lineHeight: 0.5 }}>
                        <FaAngleDoubleDown size={48} />
                    </span>
                    <p className="lead">
                        <a href={`mailto:${props.electionsEmail}?Subject=Candidature%20${props.title}`} target="_top">
                            {props.electionsEmail}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}
