import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Elections from './Elections';
import Home from './Home';
import Swag from './Swag';
import config from './config/config';

import './App.scss';

const areElectionsEnabled = true;
const isSwagEnabled = false;

const App: React.FC = () => {
    useEffect(() => {
        document.title = config.title;
    });

    return (
        <Router>
            {/* TODO : Add NavBar here */}
            <Routes>
                <Route
                    path="/"
                    element={<Home areElectionsEnabled={areElectionsEnabled} isSwagEnabled={isSwagEnabled} />}
                />
                {areElectionsEnabled && <Route path="/elections" element={<Elections />} />}
                {isSwagEnabled && <Route path="/swag" element={<Swag />} />}
                <Route
                    path="*"
                    element={
                        <div>
                            <Helmet>
                                <meta name="robots" content="noindex" />
                            </Helmet>
                            <div>Error 404</div>
                        </div>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
