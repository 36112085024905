import React from 'react';
import config from '../config/config';

interface MultiLineStringProps {
    text: string;
}

export function MultiLineStringComponent(props: MultiLineStringProps): JSX.Element {
    return (
        <>
            {props.text.split('\n').map((line: string, i) => (
                <React.Fragment key={'line ' + i}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </>
    );
}

export function executeString(s: string) {
    const strMatches = s.match(/\{config\.\w*}/g);
    const execStr = new Map<string, string>(Object.entries(config).map(([k, v]) => [`{config.${k}}`, v]));
    if (strMatches) {
        for (const val of strMatches) {
            const changeValue = execStr.has(val) ? execStr.get(val) : val;
            s = s.replace(val, changeValue === undefined ? val : changeValue);
        }
    }
    return s;
}
