import { SectionHeadingComponent } from './Margins';
import React from 'react';

export function CalendrierSectionComponent() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const firstYear = currentMonth < 8 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    const secondYear = currentMonth < 8 ? currentDate.getFullYear() : currentDate.getFullYear() + 1;
    return (
        <div className="container">
            <SectionHeadingComponent
                headingTitle={'Calendrier'}
                headingContent={
                    <>
                        Voici notre calendrier d'évènements pour l'année {firstYear}-{secondYear}
                    </>
                }
                headingExtra={
                    <div className="col-lg-12 text-center">
                        <h3 className="section-subheading">
                            <button
                                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                                data-toggle="collapse"
                                data-target="#demo"
                            >
                                Voir les évènements au calendrier
                            </button>
                        </h3>
                    </div>
                }
            />

            <div className="row">
                <div id="demo" className="collapse">
                    <h3> Aucun évènement à venir pour le moment </h3>
                    <div className="col-lg-12">
                        <ul className="timeline">{/*Anciennement le calendrier hard-coded...*/}</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
