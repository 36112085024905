export const teamRoleStrings = new Map<string, string>([
    ['direction', 'Direction'],
    ['treasury', 'Trésorerie'],
    ['education', 'Éducation'],
    ['external', 'Externe'],
    ['internal', 'Interne'],
    ['communication', 'Communications'],
    ['club', 'Comité'],
    ['webmaster', 'Webmestre'],
    ['integration', 'Intégration étudiante'],
    ['eventsDeputy', 'Adjoint évènements'],
    ['directionDeputy', 'Adjoint direction'],
    ['externalDeputy', 'Adjoint externe'],
]);
