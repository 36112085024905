import merge from 'ts-deepmerge';
import minimalInfoConfig from './info.json';
import minimalLogConfig from './log.json';
import commonConfig from './common.json';
import { WebsiteConfig } from '../interfaces/config';

const genie = process.env.REACT_APP_INFO_LOG_CONFIG === 'info' ? minimalInfoConfig : minimalLogConfig;

const genieConfig: WebsiteConfig = merge(commonConfig, genie);

export default genieConfig;
