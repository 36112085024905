import { TeamMember } from '../interfaces/config';
import { teamRoleStrings } from '../constants/team-role-strings';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import React from 'react';

export interface TeamMemberProps {
    roleName: string;
    teamMember: TeamMember;
    genieName: string;
}

export function TeamMemberComponent(props: TeamMemberProps) {
    return (
        <div className="col-6 col-md-4">
            <div className="team-member">
                <img
                    className="mx-auto rounded-circle"
                    src={`${process.env.PUBLIC_URL}/img/team/${props.genieName}/${props.roleName}.jpg`}
                    alt=""
                />
                <h4>
                    {props.teamMember.firstName}
                    <br />
                    {props.teamMember.lastName}
                </h4>
                <p className="text-muted">{teamRoleStrings.get(props.roleName)}</p>
                <ul className="list-inline social-buttons">
                    <li className="list-inline-item">
                        <a href={props.teamMember.github} target="_blank" rel="noopener noreferrer">
                            <FaGithub size={30} />
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <a href={props.teamMember.linkedin} target="_blank" rel="noopener noreferrer">
                            <FaLinkedin size={30} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}
