/**
 * Name from https://english.stackexchange.com/questions/24060/what-word-defines-a-category-suited-for-both-header-and-footer
 */
import React from 'react';
import { FaEnvelope, FaFacebookF, FaMapPin, FaPhone } from 'react-icons/fa';
import { MultiLineStringComponent } from './Text';

export interface HeaderProps {
    headerContent: JSX.Element;
}

export function HeaderComponent(props: HeaderProps) {
    return (
        <header className="masthead">
            <div className="container">
                <div className="intro-text">{props.headerContent}</div>
            </div>
        </header>
    );
}

export interface FooterProps {
    title: string;
    facebookUrl: string;
}

export function FooterComponent(props: FooterProps) {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <span className="copyright">
                            Copyright &copy; {props.title} {new Date().getFullYear()}
                        </span>
                    </div>
                    <div className="col-md-4">
                        <ul className="list-inline social-buttons">
                            <li className="list-inline-item">
                                <a href={props.facebookUrl}>
                                    <FaFacebookF size={30} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export interface SectionHeadingProps {
    headingTitle: string;
    headingContent: JSX.Element;
    headingExtra?: JSX.Element;
}

export function SectionHeadingComponent(props: SectionHeadingProps) {
    return (
        <div className="row">
            <div className="col-lg-12 text-center">
                <h2 className="section-heading text-uppercase">{props.headingTitle}</h2>
                <h3 className="section-subheading text-muted">{props.headingContent}</h3>
            </div>
            {props.headingExtra}
        </div>
    );
}

export interface ContactProps {
    contactIcon: JSX.Element;
    contactHeading: string;
    contactContent?: string;
}

export function ContactComponent(props: ContactProps) {
    return (
        <div className="col-md-4">
            <span className="service-icon">{props.contactIcon}</span>
            <h4 className="service-heading-light">{props.contactHeading}</h4>
            {props.contactContent !== undefined && (
                <p className="service-heading-light">
                    <MultiLineStringComponent text={props.contactContent} />
                </p>
            )}
        </div>
    );
}

export interface ContactSectionProps {
    email: string;
}

export function ContactSectionComponent(props: ContactSectionProps) {
    return (
        <div className="container">
            <SectionHeadingComponent headingTitle={'Contactez-nous'} headingContent={<></>} />
            <div className="row text-center">
                <ContactComponent contactIcon={<FaPhone />} contactHeading={'514 340-4747 Poste 4393'} />
                <ContactComponent contactIcon={<FaEnvelope />} contactHeading={props.email} />
                <ContactComponent
                    contactIcon={<FaMapPin />}
                    contactHeading={'Adresse'}
                    contactContent={
                        'Polytechnique Montréal\n2900, boulevard Édouard-Montpetit\nMontréal (Québec) H3T 1J4 Canada \nLocal : M-4521'
                    }
                />
            </div>
            {/* <div className="row " style={{ paddingTop: '100px' }}>
                    <div className="col-lg-12 text-center">
                        <a
                            className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                            href="https://semaine.ceginfo.polymtl.ca/"
                        >
                            Zone Entreprise
                        </a>
                    </div>
                </div> */}
        </div>
    );
}
