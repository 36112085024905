import { MerchConfig, MerchItem } from '../interfaces/config';
import { MerchSectionThumbnailComponent, ModalComponent, ModalsComponent } from './Modal';
import React from 'react';

const merchImgFolder = `${process.env.PUBLIC_URL}/img/merch`;

export interface MerchProps {
    merchConfig: MerchConfig;
}

export function MerchContentComponent(props: MerchProps) {
    return (
        <>
            {Object.entries(props.merchConfig).map(([section, merchItems]: [string, Array<MerchItem>], i: number) => (
                <div key={section}>
                    {/* Thumbnails */}
                    <MerchSectionThumbnailComponent
                        index={i}
                        id={section}
                        merchItems={merchItems}
                        imgFolder={merchImgFolder}
                    />

                    {/* Modals */}
                    <ModalsComponent
                        section={section}
                        items={merchItems}
                        imgFolder={merchImgFolder}
                        component={ModalComponent}
                    />
                </div>
            ))}
        </>
    );
}

export interface MerchOrderProps {
    swagDeadline: string;
    swagUrl: string;
}

export function MerchOrderComponent(props: MerchOrderProps) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-center">
                    <h1 className="section-heading-thick text-uppercase">Un design vous intéresse?</h1>
                    <h2 style={{ paddingBottom: '100px' }}>
                        {' '}
                        Passez votre commande avec le bouton ci-dessous puis venez payer au M-4521 avant le{' '}
                        {props.swagDeadline}!
                    </h2>
                    <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href={props.swagUrl}>
                        Commander
                    </a>
                </div>
            </div>
        </div>
    );
}
