import { Ressource, RessourceSection } from '../interfaces/config';
import { executeString } from './Text';
import React from 'react';
import { SectionHeadingComponent } from './Margins';

export interface RessourceSectionProps {
    ressourceSection: RessourceSection;
}

export function RessourceSectionComponent(props: RessourceSectionProps) {
    return (
        <div className="col-lg-4 text-center">
            <h5 className="text-uppercase">{props.ressourceSection.ressourceTitle}</h5>
            <ul className="list-unstyled">
                {props.ressourceSection.ressources.map((value: Ressource, i) => (
                    <li key={'li' + i}>
                        <a href={executeString(value.ressourceLink)}>{value.ressourceText}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export interface RessourcesSectionProps {
    ressources: Array<RessourceSection>;
}

export function RessourcesSectionComponent(props: RessourcesSectionProps) {
    return (
        <div className="container">
            <SectionHeadingComponent
                headingTitle={'Ressources'}
                headingContent={
                    <>Voici quelques liens qui vous seront utiles tout au long de vos études à Polytechnique</>
                }
            />
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    {props.ressources.map((value, i) => (
                        <RessourceSectionComponent ressourceSection={value} key={'ressource' + i} />
                    ))}
                </div>
            </div>
        </div>
    );
}
