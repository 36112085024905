import React from 'react';

import config from '../config/config';

import './Home.scss';
import { HeaderComponent, FooterComponent, ContactSectionComponent } from '../components/Margins';
import { NavigationHeaderComponent, NavigationItemComponent } from '../components/Navigation';
import { CalendrierSectionComponent } from '../components/Calendar';
import { RessourcesSectionComponent } from '../components/Ressource';
import { TeamSectionComponent } from '../components/Team';
import { ServiceSectionComponent } from '../components/Service';

interface HomeProps {
    areElectionsEnabled: boolean;
    isSwagEnabled: boolean;
}

const Home: React.FC<HomeProps> = (props: HomeProps) => (
    <>
        {/* Navigation */}
        <NavigationHeaderComponent
            title={config.title}
            menuHref={'#page-top'}
            anchors={[
                <NavigationItemComponent anchorName={'Services'} key={'services'} />,
                <NavigationItemComponent
                    anchorName={'Calendar'}
                    anchorAffichableName={'Calendrier'}
                    key={'calendar'}
                />,
                <NavigationItemComponent anchorName={'links'} anchorAffichableName={'Ressources'} key={'links'} />,
                <NavigationItemComponent anchorName={'team'} anchorAffichableName={'Équipe'} key={'team'} />,
                <NavigationItemComponent
                    anchorName={'contact'}
                    anchorAffichableName={'Nous joindre'}
                    key={'contact'}
                />,
            ]}
        />

        {/* Header */}
        <HeaderComponent
            headerContent={
                <>
                    <div className="intro-heading text-uppercase">
                        Comité étudiant
                        <br />
                        Génie {config.extendedName}
                    </div>
                    {props.areElectionsEnabled && (
                        <a className="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="/elections">
                            Élections {new Date().getFullYear()}
                        </a>
                    )}
                </>
            }
        />

        {/* Services */}
        <section className="bg-light" id="services">
            <ServiceSectionComponent
                programName={config.extendedName}
                sisterTitle={config.sisterTitle}
                plaintesAEP={config.plaintesAEP}
                swagEnabled={props.isSwagEnabled}
            />
        </section>

        {/* Calendar */}
        <section id="calendar">
            <CalendrierSectionComponent />
        </section>

        {/* Services */}
        <section className="bg-light" id="links">
            <RessourcesSectionComponent ressources={config.ressources} />
        </section>

        {/* Team */}
        <section id="team">
            <TeamSectionComponent team={config.team} programName={config.extendedName} genieName={config.name} />
        </section>

        {/* Contact */}
        <section id="contact">
            <ContactSectionComponent email={config.email} />
        </section>

        {/* Footer */}
        <FooterComponent title={config.title} facebookUrl={config.facebookUrl} />
    </>
);

export default Home;
