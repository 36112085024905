import React from 'react';

import { MerchConfig } from '../interfaces/config';
import config from '../config/config';
import merch from '../config/merch.json';

import './Swag.scss';
import { FooterComponent, HeaderComponent } from '../components/Margins';
import { NavigationHeaderComponent, NavigationItemComponent } from '../components/Navigation';
import { MerchOrderComponent, MerchContentComponent } from '../components/Merch';

export const merchSectionStrings = new Map<string, string>([
    ['shirts', 'T-shirts'],
    ['sweaters', 'Hoodies & crewnecks'],
    ['misc', 'Divers'],
]);

const merchConfig: MerchConfig = merch;

const Swag: React.FC = () => (
    <>
        {/* Navigation */}
        <NavigationHeaderComponent
            title={config.title}
            menuHref={'/'}
            anchors={Object.keys(merchConfig)
                .map((section: string) => (
                    <NavigationItemComponent
                        anchorName={section}
                        anchorAffichableName={merchSectionStrings.get(section)}
                        key={section}
                    />
                ))
                .concat([
                    <NavigationItemComponent anchorName={'order'} anchorAffichableName={'Commander'} key={'order'} />,
                ])}
        />

        {/* Header */}
        <HeaderComponent
            headerContent={
                <div className="intro-heading text-uppercase">
                    Comité étudiant
                    <br />
                    Génie {config.extendedName}
                </div>
            }
        />

        {/* Merch */}
        <MerchContentComponent merchConfig={merch} />

        {/* Order */}
        <section className="page-section portfolio" id="order">
            <MerchOrderComponent swagDeadline={config.swagDeadline} swagUrl={config.swagUrl} />
        </section>

        {/* Footer */}
        <FooterComponent title={config.title} facebookUrl={config.facebookUrl} />
    </>
);

export default Swag;
